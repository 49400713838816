import React from 'react'
import { Close, Person, Chatbubbles, Save } from 'react-ionicons'

interface Props {
    onClose: () => void
    userName: string | null,
    setSettings: (string: string, checkedChat: boolean, checkedParticipant: boolean) => void
}

export default function SettingsModal(props: Props) {
    const { onClose, userName, setSettings } = props
    const [newUserName, setNewUserName] = React.useState('');
    const [checkedParticipantNotification, setCheckedParticipantNotification] = React.useState(false);
    const [checkedChatNotification, setCheckedChatNotification] = React.useState(false);


    React.useEffect(() => {
        setNewUserName(userName)
    }, [])
    const saveChanges = () => {
        console.log('username Changed')
    }
    return (

        <div >
            <div className="settings-top" >
                <div className="settings-exit-button">
                    <Close onClick={() => onClose()} />
                </div>
            </div>
            <div className="settings-container">
                <p><Person/> Username:</p>
                <input type="text" className="custom-chat-input" placeholder="Please Enter Your Name" value={newUserName} onChange={e => setNewUserName(e.target.value)} />
                <br />
                <label>
                    <input
                        name="isGoing"
                        type="checkbox"
                        checked={checkedParticipantNotification}
                        onChange={() => (setCheckedParticipantNotification(!checkedParticipantNotification))} />
                     Mute Participant Notification
                </label>
                <br />
                <label>
                    <input
                        name="isGoing"
                        type="checkbox"
                        checked={checkedChatNotification}
                        onChange={() => setCheckedChatNotification(!checkedChatNotification)} />
                    Mute Chat Notification
                </label>
                <br />
                <button onClick={() => setSettings(newUserName, checkedParticipantNotification, checkedChatNotification)} className="form-button" type="button" value="Submit">Save</button>
            </div>
        </div>
    )
}
