import React from 'react'
interface Props {
    imageLink: string,
    message: string,
    userName: string,
    time: string
}

export default function ParticipantChatMessage(props: Props): JSX.Element {
    const {imageLink, message, userName, time} = props;
    return (
        <div className="chat-message-card">
        <img className="img radius circle p-10" src={imageLink} width="40" height="40"/>
        <div>
        <div className="message-area">
        <p>{message}</p>
         </div>
         <span className="time-right">{userName}, {time}</span>
         </div>
    </div>

        
    )
}
