import './scss/main.scss';
import CallManager from './components/callManager';
import React from 'react';

function App(): JSX.Element {
	const query = new URLSearchParams(window.location.search);
	const callId = query.get('room') ?? 'default';
	const userName = query.get('userName');
	React.useEffect(() => {
		// if(!callId) {
		// 	window.location.href = 'https://support3e.appac.io/';
		// }
	}, []);

	if(callId) {
		return (
			<div className="App">
				<CallManager roomId={callId} userName={userName} />
			</div>
		);
	} else {
		return (<p>.....</p>)
	}
	
}

export default App;

