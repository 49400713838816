import React from 'react'
interface Props {
    imageLink: string,
    message: string,
    userName: string,
    time: string
}
export default function UserChatMessage(props: Props): JSX.Element {
    const {imageLink, message, userName, time} = props;
    return (
        <div className="chat-message-card my-message">
            <div>
            <div className="message-area user">
            <p>{message}</p>
             </div>
             <span className="time-right">{time}</span>
             </div>
             {/* <img className="img radius circle p-10" src={imageLink} width="40" height="40"/> */}

        </div>
        
    )
}
